<template>
  <div>
    <div class="main-box">
      <div class="text-start mb-26 c-gray">首页 > 要闻</div>

      <div class="text-start mb-26">
        <h1>建设要闻</h1>
      </div>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="建设要闻" name="first">
          <div>
            <el-row class="mb-24">
              <el-col :span="12">
                <el-input
                  size="small"
                  placeholder="请输入内容"
                  v-model="keyWord"
                >
                  <template slot="prepend"
                    ><i class="el-icon-search"></i
                  ></template>
                </el-input>
              </el-col>
              <el-col :span="2">
                <el-button size="small" type="primary" @click="handSearch(1)"
                  >搜索</el-button
                >
              </el-col>
            </el-row>
            <div class="new-box">
              <div
                class="list-item"
                @click="handItem(item)"
                v-for="(item, index) in newsList"
                :key="index"
              >
                <el-row style="padding: 0 20px">
                  <el-col :span="12" class="text-start">{{
                    item.informationTitle || "-"
                  }}</el-col>
                  <el-col :span="2" :offset="10" class="c-gray">{{
                    item.dateText
                  }}</el-col>
                </el-row>
                <el-divider v-if="index !== 9"></el-divider>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="各地行业信息" name="second">
          <div>
            <el-row class="mb-24">
              <el-col :span="12">
                <el-input
                  v-model="keyWord"
                  size="small"
                  placeholder="请输入内容"
                >
                  <template slot="prepend"
                    ><i class="el-icon-search"></i
                  ></template>
                </el-input>
              </el-col>
              <el-col :span="2">
                <el-button size="small" @click="handSearch(2)" type="primary"
                  >搜索</el-button
                >
              </el-col>
            </el-row>
            <div class="new-box">
              <div
                class="list-item"
                @click="handItem(item)"
                v-for="(item, index) in newList"
                :key="index"
              >
                <el-row style="padding: 0 20px">
                  <el-col :span="12" class="text-start">{{
                    item.title || "-"
                  }}</el-col>
                  <el-col :span="2" :offset="10" class="c-gray">{{
                    item.time
                  }}</el-col>
                </el-row>
                <el-divider v-if="index !== 9"></el-divider>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="部发文件" name="third">
          <div>
            <el-row class="mb-24">
              <el-col :span="12">
                <el-input
                  size="small"
                  placeholder="请输入内容"
                  v-model="keyWord"
                >
                  <template slot="prepend"
                    ><i class="el-icon-search"></i
                  ></template>
                </el-input>
              </el-col>
              <el-col :span="2">
                <el-button size="small" @click="handSearch(2)" type="primary"
                  >搜索</el-button
                >
              </el-col>
            </el-row>
            <div class="new-box">
              <div
                class="list-item"
                @click="handItem(item)"
                v-for="(item, index) in newsList"
                :key="index"
              >
                <el-row style="padding: 0 20px">
                  <el-col :span="12" class="text-start">{{
                    item.informationTitle || "-"
                  }}</el-col>
                  <el-col :span="2" :offset="10" class="c-gray">{{
                    item.dateText
                  }}</el-col>
                </el-row>
                <el-divider v-if="index !== 9"></el-divider>
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>

      <div class="paging">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-size="10"
          layout="prev, pager, next, jumper"
          :total="this.total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import manual from "./components/manual.vue";
import videoProduct from "./components/video.vue";
import platformProduct from "./components/product.vue";
import { getIndustryInformationPage, newsPage } from "@/api/engineering";

export default {
  data() {
    return {
      keyWord: "",
      newsList: [],
      input1: "",
      activeName: "first",
      activeNames: "",
      current: 1,
      size: 10,
      informationTitle: null,
      newList: [],
      total: 0,
    };
  },
  components: {
    manual,
    videoProduct,
    platformProduct,
  },
  mounted() {
    this.newsPage();
    document.title = "行业资讯";
    let params = window.location.href.split("?")[1];
    if (params == 1) {
      this.activeName = "first";
    }
    if (params == 2) {
      this.activeName = "second";
    }
    if (params == 3) {
      this.activeName = "third";
    }
    this.getIndustryInformation();
  },
  methods: {
    handItem(item) {
      let theUrlPrefix = window.location.origin;
      if (window.location.origin.includes("trial.yanxin7.cn")) {
        theUrlPrefix = "https://trial.yanxin7.cn";
      }
      if (
        window.location.origin.includes("dev.yanxin7.cn") ||
        window.location.origin.includes("localhost")
      ) {
        theUrlPrefix = "https://dev.yanxin7.cn";
      }
      let url =
        theUrlPrefix +
        "/api/v1/industry/detail?industryId=" +
        item.informationId;
      console.log("xxxxxsss", url);
      window.open(url, "_blank");
    },

    // 获取各地信息
    async newsPage() {
      try {
        const { data } = await newsPage({
          childType: "各地信息",
          size: this.size,
          current: this.current,
          title: this.keyWord,
          type: "各地信息",
        });
        this.newList = data.records;
        this.total = data.total;
      } catch (error) {
        console.log("newsPageError", error);
      }
    },
    handleClick(tab, event) {
      console.log(tab, event);
      if (tab.name == "second") {
        this.newsPage();
      } else {
        this.getIndustryInformation();
      }
    },

    handleSizeChange(e) {
      this.size = e;
      if (this.activeName == "first") {
        this.getIndustryInformation();
      }
      if (this.activeName == "second") {
        this.newsPage();
      }
      if (this.activeName == "third") {
        this.getIndustryInformation();
      }
    },
    handleCurrentChange(e) {
      this.current = e;
      if (this.activeName == "first") {
        this.getIndustryInformation();
      }
      if (this.activeName == "second") {
        this.newsPage();
      }
      if (this.activeName == "third") {
        this.getIndustryInformation();
      }
    },
    handSearch(e) {
      this.informationTitle = e;
      if (e == 2) {
        this.getIndustryInformation();
        return;
      }
      this.getIndustryInformation();
    },
    async getIndustryInformation() {
      try {
        const { data } = await getIndustryInformationPage({
          current: this.current,
          size: this.size,
          industryInformationClassId: this.industryInformationClassId,
          informationTitle: this.keyWord,
        });
        let records = data.records || [];
        this.total = data.total;
        records.forEach((element) => {
          let updateTime = element.updateTime || "";
          element.dateText = updateTime;
          if (updateTime) {
            element.dateText = updateTime.split(" ")[0];
          }
        });
        this.newsList = records;
        console.log("dasdadasd", records);
      } catch (error) {
        console.log("获取资讯报错", error);
      }
    },
  },
};
</script>
<style scoped>
.main-box {
  padding-bottom: 40px;
  min-height: 800px;
  width: 1200px;
  margin-left: 50%;
  transform: translateX(-50%);
  padding-top: 40px;
}
.main-content {
  display: flex;
}
.left-menu {
  width: 200px;
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
  background: #fbfbfb;
}
::v-deep .el-collapse-item__header {
  border: none;
  background: #fbfbfb;
}
::v-deep .el-collapse-item__wrap {
  border: none;
  background: #fbfbfb;
}
::v-deep.el-collapse {
  border: none;
}
::v-deep .el-tabs__item {
  text-align: center;
}
.text-start {
  text-align: start;
}
.mb-26 {
  margin-bottom: 26px;
}
.c-gray {
  color: #888;
}
.flex-between {
  display: flex;
  justify-content: space-between;
}
.mb-56 {
  margin-bottom: 26px;
}
.mb-24 {
  margin-bottom: 20px;
}
.collapse-item {
  cursor: pointer;
  position: relative;
}
.collapse-item::before {
  color: #007ef2;
  content: ".";
  position: absolute;
  display: block;
  width: 2px;
  height: 24px;
  background: #007ef2;
  opacity: 1;
}
.collapse-item:last-child {
  margin-bottom: 0px;
}
.flex-row {
  display: flex;
}
.new-box {
  width: 1200px;
  border: 1px solid rgb(211, 210, 210);
  padding: 20px 0;
}
.paging {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}
.list-item {
  cursor: pointer;
}
</style>