<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
          <div class="main-content">
            <!-- <div class="left-menu">
              <el-collapse v-model="activeNames" @change="handleChange">
                <el-collapse-item title="　投标管理　　　" name="1">
                  <div class="mb-24 collapse-item">项目加入管理</div>
                  <div class="mb-24 collapse-item">项目加入管理</div>
                </el-collapse-item>
                <el-collapse-item title="　投标管理　　　" name="2">
                  <div class="mb-24 collapse-item">项目加入管理</div>
                  <div class="mb-24 collapse-item">项目加入管理</div>
                </el-collapse-item>
              </el-collapse>
            </div> -->
            <div>
              <el-image
                style="width: 579px"
                src="https://oss.yanxin7.cn/2023-08-25/ec25847d987e1d1871f6a83c87c370c3"
              ></el-image>
            </div>
          </div>
      </el-tabs>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeName: "first",
      activeNames: "",
    };
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    handleOpen() {},
    handleClose() {},
    handleChange() {},
  },
};
</script>
<style scoped>
.main-box {
  min-height: 800px;
  width: 1200px;
  margin-left: 50%;
  transform: translateX(-50%);
  padding-top: 40px;
}
.main-content {
  display: flex;
}
.left-menu {
  width: 200px;
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
  background: #fbfbfb;
}
::v-deep .el-collapse-item__header {
  border: none;
  background: #fbfbfb;
}
::v-deep .el-collapse-item__wrap {
  border: none;
  background: #fbfbfb;
}
::v-deep.el-collapse {
  border: none;
}
::v-deep .el-tabs__item {
  width: 120px !important;
  text-align: center;
}
.text-start {
  text-align: start;
}
.mb-26 {
  margin-bottom: 26px;
}
.c-gray {
  color: #888;
}
.flex-between {
  display: flex;
  justify-content: space-between;
}
.mb-56 {
  margin-bottom: 26px;
}
.mb-24 {
  margin-bottom: 12px;
}
.collapse-item {
  cursor: pointer;
  position: relative;
}
.collapse-item::before {
  color: #007ef2;
  content: '.';
  position: absolute;
  display: block;
  width: 2px;
  height: 24px;
  background: #007ef2;
  opacity: 1;
}
.collapse-item:last-child {
  margin-bottom: 0px;
}
</style>